'use strict';

const HISTORY_EVENT_TYPE_ID = {
  ADD_TO_SEGMENT: 12,
  ADDED_TO_IGNORE_CUSTOM_CONTACT_LIST: 56,
  ADDED_TO_IGNORE_ENGAGE_CONTACT_LIST: 39,
  CAMPAIGN_CLICKED: 19,
  CAMPAIGN_OPENED: 18,
  CAMPAIGN_TARGETED: 17,
  CAMPAIGN_UNSUBSCRIBED: 55,
  CLICK_ON_MAIL: 3,
  COMMENT_ADDED_FROM_ENGAGE_PHONE_WITHOUT_INDICATION_RESULT: 8,
  COMMENT_CREATED: 11,
  CREDIT_CONSUMPTION_CONSULTATION: 14,
  CREDIT_CONSUMPTION_CRM: 16,
  CREDIT_CONSUMPTION_CSV: 15,
  CREDIT_CONSUMPTION_MOBILE_PHONE: 48,
  CREATION_OF_A_REMINDER: 10,
  CUSTOM_LEAD_CREATED: 22,
  ENGAGEMENT_PER_EMAIL_BLOCKED: 65,
  ENGAGEMENT_PER_EMAIL_FAIL: 32,
  ENGAGEMENT_PER_MAIL: 1,
  INTERACTION_DELETED: 40,
  LEAD_SUGGESTION_ACCEPTED: 20,
  LEAD_SUGGESTION_OPPORTUNITY: 25,
  LEAD_SUGGESTION_REFUSED: 21,
  LEAD_SUGGESTION_RISK: 24,
  MAIL_AUTOREPLIED: 64,
  MAIL_OPENING: 2,
  MAIL_REPLIED: 34,
  MANUAL_STATUS_CHANGE: 23,
  MODIFICATION: 28,
  PUSH_TO_CRM: 33,
  REMOVE_FROM_SEGMENT: 13,
  REMINDER_ADDED_FROM_ENGAGE_PHONE_WITHOUT_INDICATION_RESULT: 9,
  REMINDER_NOTIFY: 31,
  SEQUENCE_TARGET_REPLAY_MANUALLY: 57,
  SUCCESSFUL_CALL: 26,
  SUCCESSFUL_CALL_WITH_COMMENT: 5,
  SUCCESSFUL_CALL_WITHOUT_COMMENT: 4,
  TASK_CREATED: 35,
  TASK_DELETED: 37,
  TASK_FINISHED: 36,
  TASK_UPDATED: 38,
  UNSUCCESSFUL_CALL: 27,
  UNSUCCESSFUL_CALL_WITH_COMMENT: 7,
  UNSUCCESSFUL_CALL_WITHOUT_COMMENT: 6,
  USER_CREDIT: 43,
};

export {
  HISTORY_EVENT_TYPE_ID,
};
