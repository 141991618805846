'use strict';

const PERMISSIONS = {
  ADVANCED_SEARCH: 'ADVANCED_SEARCH',
  BUSINESS_ALERTS: 'BUSINESS_ALERTS',
  CAMPAIGNS: 'CAMPAIGNS',
  CAMPAIGNS_DRAFT: 'CAMPAIGNS_DRAFT',
  CAMPAIGNS_SCHEDULED: 'CAMPAIGNS_SCHEDULED',
  CAMPAIGNS_SENT: 'CAMPAIGNS_SENT',
  CAMPAIGNS_SETTINGS: 'CAMPAIGNS_SETTINGS',
  CONFERENCES: 'CONFERENCES',
  HOME_HELP: 'HOME_HELP',
  HOME_NEWS: 'HOME_NEWS',
  HOME_PROFILE: 'HOME_PROFILE',
  HOME_PROSPECT: 'HOME_PROSPECT',
  MY_PROSPECTING_MANAGER_ACCESS: 'MY_PROSPECTING_MANAGER_ACCESS',
  MY_PROSPECTING_SMART_SCORING: 'MY_PROSPECTING_SMART_SCORING',
  MY_PROSPECTING_MY_PROSPECTS: 'MY_PROSPECTING_MY_PROSPECTS',
  NAVBAR_PROSPECTS: 'NAVBAR_PROSPECTS',
  PERSONAL_SPACE: 'PERSONAL_SPACE',
  PERSONAL_SPACE_ACCOUNT_SEGMENTS: 'PERSONAL_SPACE_ACCOUNT_SEGMENTS',
  PERSONAL_SPACE_ACTIVITY: 'PERSONAL_SPACE_ACTIVITY',
  PERSONAL_SPACE_CHANGE_PASSWORD: 'PERSONAL_SPACE_CHANGE_PASSWORD',
  PERSONAL_SPACE_CONTACT_SEGMENTS: 'PERSONAL_SPACE_CONTACT_SEGMENTS',
  PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE: 'PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE',
  PERSONAL_SPACE_CREDIT_CONSUMPTION: 'PERSONAL_SPACE_CREDIT_CONSUMPTION',
  PERSONAL_SPACE_CREDIT_CONSUMPTION_CONSULTATION: 'PERSONAL_SPACE_CREDIT_CONSUMPTION_CONSULTATION',
  PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT: 'PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT',
  PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION: 'PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION',
  PERSONAL_SPACE_DASHBOARD: 'PERSONAL_SPACE_DASHBOARD',
  PERSONAL_SPACE_EMAIL_CONNECTION: 'PERSONAL_SPACE_EMAIL_CONNECTION',
  PERSONAL_SPACE_EXPORT: 'PERSONAL_SPACE_EXPORT',
  PERSONAL_SPACE_LOGOUT: 'PERSONAL_SPACE_LOGOUT',
  PERSONAL_SPACE_MY_ACCOUNT: 'PERSONAL_SPACE_MY_ACCOUNT',
  PERSONAL_SPACE_MY_ALERTS: 'PERSONAL_SPACE_MY_ALERTS',
  PERSONAL_SPACE_MY_ACTIVITY: 'PERSONAL_SPACE_MY_ACTIVITY',
  PERSONAL_SPACE_MY_NETWORK: 'PERSONAL_SPACE_MY_NETWORK',
  PERSONAL_SPACE_NOTIFICATIONS: 'PERSONAL_SPACE_NOTIFICATIONS',
  PERSONAL_SPACE_NOTIFICATIONS_SETTINGS: 'PERSONAL_SPACE_NOTIFICATIONS_SETTINGS',
  PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL: 'PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL',
  PERSONAL_SPACE_PARAMETERS: 'PERSONAL_SPACE_PARAMETERS',
  PERSONAL_SPACE_PROFILE: 'PERSONAL_SPACE_PROFILE',
  PERSONAL_SPACE_REQUESTS: 'PERSONAL_SPACE_REQUESTS',
  PERSONAL_SPACE_REQUEST_HELP: 'PERSONAL_SPACE_REQUEST_HELP',
  PERSONAL_SPACE_SAVED_SEARCHES: 'PERSONAL_SPACE_SAVED_SEARCHES',
  PERSONAL_SPACE_TEMPLATES: 'PERSONAL_SPACE_TEMPLATES',
  QUICK_SEARCH: 'QUICK_SEARCH',
  QUICK_SEARCH_ALL_RESULTS: 'QUICK_SEARCH_ALL_RESULTS',
  QUICK_SEARCH_COMPANIES: ' QUICK_SEARCH_COMPANIES',
  QUICK_SEARCH_CONFERENCES: 'QUICK_SEARCH_CONFERENCES',
  QUICK_SEARCH_CONTACTS: ' QUICK_SEARCH_CONTACTS',
  QUICK_SEARCH_NEWS: 'QUICK_SEARCH_NEWS',
  SEQUENCES: 'SEQUENCES',
  SEQUENCES_DRAFT: 'SEQUENCES_DRAFT',
  SEQUENCES_PROGRAMMED: 'SEQUENCES_PROGRAMMED',
  SEQUENCES_ONGOING: 'SEQUENCES_ONGOING',
  SEQUENCES_PAUSED: 'SEQUENCES_PAUSED',
  SEQUENCES_FINISHED: 'SEQUENCES_FINISHED',
  SEQUENCES_ARCHIVED: 'SEQUENCES_ARCHIVED',
  SEQUENCES_SETTINGS: 'SEQUENCES_SETTINGS',
};

export {
  PERMISSIONS,
};
