<template>
  <v-app>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      class="progress-linear"
      top
      color="primary"
      fixed
    />

    <v-main v-if="user_data">
      <div class="main-container">
        <header-element />

        <div
          class="content"
        >
          <slot />
          <div
            class="overlay-hidden"
            :class="{'overlay-active': showing_quick_search_results}"
          />
        </div>

        <div class="footer">
          <footer-element />
          <div
            class="overlay-hidden"
            :class="{'overlay-active': showing_quick_search_results}"
          />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { useIntercom } from '@homebaseai/vue3-intercom';
import { mapGetters, mapActions } from 'vuex';
import configuration from '@/configurations';
import defineAbilitiesFor from '@/plugins/ability';
import { usePostHogProvider } from '@/composables';

import FooterElement from '@/components/elements/FooterElement';
import HeaderElement from '@/components/elements/HeaderElement';

export default {
  name: 'AppLayout',
  components: {
    FooterElement,
    HeaderElement,
  },
  setup() {
    return {
      intercom: useIntercom(),
    };
  },
  computed: {
    ...mapGetters({
      loading: 'app_layout/isLoading',
      showing_quick_search_results: 'app_layout/showingQuickSearchResults',
      user_data: 'user/userData',
    }),
  },
  async mounted() {
    const updatedAbilities = defineAbilitiesFor(this.user_data);

    this.$ability.update(updatedAbilities.rules);

    // Initialize and provide PostHog instance
    const posthog_instance = usePostHogProvider();

    this.initPosthogRepository(posthog_instance);

    const initIntercom = ({ user_hash }) => {
      this.intercom.boot({
        app_id: configuration.intercom_app_id,
        email: this.user_data.email,
        name: `${this.user_data.first_name} ${this.user_data.last_name}`,
        user_hash,
        user_id: this.user_data.id,
        Phone: this.user_data.phone,
      });
    };

    if (configuration.intercom_widget_authorized_url.includes(window.location.host)) {
      const user_hash = await this.getIntercomVerifiedHash();

      initIntercom({ user_hash });
    }
  },
  methods: {
    ...mapActions({
      initPosthogRepository: 'event_capture/initPosthogRepository',
      getIntercomVerifiedHash: 'user/getIntercomVerifiedHash',
    }),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/constants/variables.module.scss';

  .progress-linear {
    z-index: map-get($zindex, progress_linear);
  }

  .content {
    min-height: calc(100vh - 100px);
    position: relative;
  }

  .footer {
    position: relative;
  }

  .overlay-hidden {
    display: none;
  }

  .overlay-active {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: map-get($zindex, quick_search_overlay);
  }
</style>
