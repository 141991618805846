import {
  export_repository,
} from '@/repositories';

import {
  EXPORT_LIMIT,
  EXPORT_TYPE,
} from '@/constants';

const state = () => ({
  export_list: null,
});

const getters = {
  exportList: (state) => state.export_list,
};

const actions = {
  async getDownloadLink(_, export_id) {
    const response = await export_repository.getDownloadLink(export_id);

    return response;
  },
  async searchExports({ rootState, commit, state }, input) {
    const {
      offset,
    } = input;

    const response = await export_repository.searchExports({
      limit: EXPORT_LIMIT,
      offset,
      sort_list: ['-start_date'],
      user_id_list: [rootState.user.token_data.user_id],
    });

    if (offset === 0) {
      commit('set_export_list', response.export_list);

      return response.export_list;
    }

    commit('set_export_list', state.export_list.concat(response.export_list));

    return state.export_list.concat(response.export_list);
  },
  async searchEmailRentalExports({ rootState, commit, state }, input) {
    const {
      offset,
    } = input;

    const {
      export_list,
    } = await export_repository.searchExports({
      export_type_list: [EXPORT_TYPE.EMAIL_RENTAL],
      limit: EXPORT_LIMIT,
      offset,
      sort_list: ['-start_date'],
      user_id_list: [rootState.user.token_data.user_id],
    });

    const {
      location_history_list,
    } = await export_repository.searchEmailRentalUsage({
      user_id_list: [rootState.user.token_data.user_id],
    });

    const enriched_export_list = export_list.map((item) => {
      const history_list = location_history_list
        .filter((history) => history.export_id === item.id);

      return {
        ...item,
        history_list,
      };
    });

    if (offset === 0) {
      commit('set_export_list', enriched_export_list);

      return enriched_export_list;
    }

    commit('set_export_list', state.export_list.concat(enriched_export_list));

    return state.export_list.concat(enriched_export_list);
  },
};

const mutations = {
  set_export_list(state, export_list) {
    state.export_list = export_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
