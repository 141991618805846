import { axios_nomination } from '@/plugins/axios';

import configuration from '@/configurations';

class UserRepository {
  /**
   * @param {Number} user_id
   * @returns {Promise}
   */
  async getUserById(user_id) {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/users/${user_id}`);

    return result.data;
  }

  /**
   * @param {Number} id_list
   * @returns {Promise}
   */
  async search(criteria) {
    const {
      organization_id_list,
      status_id_list,
      id_list,
      limit,
      offset,
      sort_list,
    } = criteria;

    const params = {
      organization_id_list,
      status_id_list,
      id_list,
      limit,
      offset,
      sort_list,
    };
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/users`,
        {
          params,
        });

    return result.data;
  }

  /**
   * @param {Number} organization_id
   *
   * @returns {Promise}
   */
  async getOrganizationById(organization_id) {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/organizations/${organization_id}`);

    return result.data;
  }

  /**
   *
   * @returns {Promise}
   */
  async getUsersGlobalConfigurations() {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/users/global-configurations`);

    return result.data;
  }

  /**
   *
   * @param {Number} user_id
   * @param {String[]} name_list
   * @returns
   */
  async getUserPropertyList(user_id, name_list) {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v2/users/properties`, {
        params: {
          name_list,
          user_id_list: [user_id],
        },
      });

    return result.data;
  }

  /**
   *
   * @param {Object} data
   * @param {Number} data.user_id
   * @param {String} data.name
   * @param {String} data.value
   * @returns
   */
  async createUserProperty(data) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v2/users/properties`,
        data
      );

    return result.data;
  }

  /**
   *
   * @param {Number} id
   * @param {Object} data
   * @param {String} data.value
   */
  async updateUserProperty(id, data) {
    const result = await axios_nomination
      .patch(
        `${configuration.api_host}/v2/users/properties/${id}`,
        data
      );

    return result.data;
  }

  async deleteUserProperty(id) {
    const result = await axios_nomination
      .delete(`${configuration.api_host}/v2/users/properties/${id}`);

    return result.data;
  }

  async getUserFormationList(user_id) {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/users/${user_id}/formations`);

    return result.data;
  }

  async getUserExperienceList(user_id) {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/users/${user_id}/experiences`);

    return result.data;
  }

  getUserDataLocalStorage() {
    return localStorage.user_data
      ? JSON.parse(localStorage.user_data)
      : null;
  }

  setUserDataLocalStorage(user_data) {
    localStorage.user_data = user_data;
  }

  removeUserDataLocalStorage() {
    localStorage.removeItem('user_data');
  }

  async updateUser(user_id, input) {
    const result = await axios_nomination
      .patch(`${configuration.api_host}/v1/users/${user_id}`,
        input
      );

    return result.data;
  }

  async getIntercomVerifiedHash() {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/users/intercom-verified-hash`);

    return result.data.user_hash;
  }
}

const user_repository = new UserRepository();

export {
  user_repository,
};
