import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class ExportRepository {

  /**
   * @param {Number} export_id
   *
   * @returns {Promise}
   */
  async getDownloadLink(export_id) {
    const result = await axios_nomination.get(`${configuration.api_host}/v1/exports/${export_id}/download`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchExports(input) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/exports`,
      {
        params: input,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchEmailRentalUsage(input) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/exports/email-rental/usage`,
      {
        params: input,
      }
    );

    return result.data;
  }
}

const export_repository = new ExportRepository();

export {
  export_repository,
};
