export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
        "average_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux moyen Nomination"])},
        "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
        "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "credits_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits restants"])},
        "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALUT i18n !!"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou"])},
        "pagination_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
        "renewed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement le"])},
        "select_the_x_decision_makers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sélectionner le décideur"]), _normalize(["Sélectionner les ", _interpolate(_named("count")), " décideurs"])])},
        "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expéditeur"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches"])},
        "task_type_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel"])},
        "task_type_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
        "task_type_linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
        "task_type_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "x_decision_makers_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 décideur sélectionné"]), _normalize([_interpolate(_named("count")), " décideurs sélectionnés"])])}
      },
      "en": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
      }
    }
  })
}
